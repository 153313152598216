const navListData = [
    {
        _id: 1,
        link: '/',
        name: `accueil`,
        active: true
    }, 
    {
        _id: 2,
        link: '#services',
        name: `tarif`,
        // name: `activités et services`,
        active: false
    },
    // {
    //     _id: 3,
    //     link: '#bar',
    //     name: `j-bar`,
    //     active: false
    // },
    // {
    //     _id: 4,
    //     link: '#faqs',
    //     name: `faq`,
    //     active: false
    // },
    // {
    //     _id: 2,
    //     link: '#schedule',
    //     name: `schedule`,
    //     active: false
    // },
    // {
    //     _id: 3,
    //     link: '#trend',
    //     name: `manger`,
    //     active: false
    // },
    {
        _id: 5,
        link: '#contact',
        name: `nous joindre`,
        active: false
    },
];

export default navListData;