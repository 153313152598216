import React from 'react';
import './homeServices.css';

function HomeServices() {
    // useEffect(() => {
    //     const tooltipTriggerList = [].slice.call(
    //       document.querySelectorAll('[data-bs-toggle="tooltip"]')
    //     );
    //     tooltipTriggerList.forEach((tooltipTriggerEl) => {
    //       new bootstrap.Tooltip(tooltipTriggerEl);
    //     });
    //   }, []);

    return (
        <section id="services" className="services">
            <div className="container-fluid">
                <div className="row">
                    <h4 className="section-title">Tarif <small>&</small> Promotion</h4>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="services__card card-regular">
                            <i className="fa-solid fa-golf-ball-tee services__card-icon"></i>
                            <div className="services__card__title">Régulier</div>
                            <div className="services__card__info">(Capacité maximale de 4 joueurs)</div>
                            <div className="services__card__item">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">Lundi au Vendredi</h6>
                                    <h6 className="services__card__item-text-description">Jusqu'à 17h00</h6>
                                </div>
                                <div className="services__card__item-price">30<small>$</small>/h</div>
                            </div>
                            <br />
                            <div className="services__card__item">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">Soir et fin de semaine</h6>
                                    {/* <p className="services__card__item-text-description">Jusqu'a 17h00</p> */}
                                </div>
                                <div className="services__card__item-price">35$/h</div>
                            </div>
                            <a 
                                className="btn btn-warning rounded-5 btn-lg mt-5" 
                                href="https://zonegolfinprod.azurewebsites.net"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Réserver
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="services__card card-vip">
                            <i className="fa-solid fa-crown services__card-icon"></i>
                            <div className="services__card__title">Golf VIP</div>
                            <div className="services__card__info">(Capacité maximale de 12 joueurs)</div>
                            <div className="services__card__item">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">Lundi au Vendredi</h6>
                                    <h6 className="services__card__item-text-description">Jusqu'à 17h00</h6>
                                </div>
                                <div className="services__card__item-price">50$/h</div>
                            </div>
                            <br />
                            <div className="services__card__item">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">Soir et fin de semaine</h6>
                                    {/* <p className="services__card__item-text-description">Jusqu'a 17h00</p> */}
                                </div>
                                <div className="services__card__item-price">55$/h</div>
                            </div>
                            <a 
                                className="btn btn-primary rounded-5 btn-lg mt-5" 
                                href="tel:+14186597656"
                            >
                                <i className="fa-solid fa-phone"></i> (418)-659-7656
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-5 mt-md-5">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="services__card card-vip">
                            <i className="fa-solid fa-tv services__card-icon"></i>
                            <div className="services__card__title">Curve</div>
                            <div className="services__card__info">(Capacité maximale de 4 joueurs)</div>
                            <div className="services__card__item">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">Lundi au Vendredi</h6>
                                    <h6 className="services__card__item-text-description">Jusqu'à 17h00</h6>
                                </div>
                                <div className="services__card__item-price">35$/h</div>
                            </div>
                            <br />
                            <div className="services__card__item">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">Soir et fin de semaine</h6>
                                    {/* <p className="services__card__item-text-description">Jusqu'a 17h00</p> */}
                                </div>
                                <div className="services__card__item-price">40$/h</div>
                            </div>
                            <a 
                                className="btn btn-primary rounded-5 btn-lg mt-5" 
                                href="tel:+14186597656"
                            >
                                <i className="fa-solid fa-phone"></i> (418)-659-7656
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="services__card card-karaoke">
                            <i className="fa-solid fa-microphone services__card-icon"></i>
                            <div className="services__card__title">Karaoké - Événement privé</div>
                            <div className="text-center services__card__info">(Capacité maximale de 12 joueurs)</div>
                            <br />
                            <div className="services__card__item mt-2">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">En tout temps</h6>
                                    {/* <h6 className="services__card__item-text-description">+5$/h une personne additionnelle</h6>
                                    <h6 className="services__card__item-text-description">7h - 20h</h6> */}
                                </div>
                                <div className="services__card__item-price">150$/h</div>
                            </div>
                            <br />
                            <br />
                            {/* <br />
                            <div className="services__card__item">
                                <div className="services__card__item-text">
                                    <h6 className="services__card__item-text-title">La nuit <small>(maximum de 12 personnes)</small></h6>
                                    <h6 className="services__card__item-text-description">20h - fermeture</h6>
                                </div>
                                <div className="services__card__item-price">150$/h</div>
                            </div> */}
                            <a 
                                className="btn btn-primary rounded-5 btn-lg mt-5" 
                                href="tel:+14186597656"
                            >
                                <i className="fa-solid fa-phone"></i> (418)-659-7656
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeServices